import React, { useEffect, useState } from 'react';
import {Container} from "react-bootstrap";
import ReactMarkdown from 'react-markdown';
//import remarkGfm from 'remark-gfm';
import rehypeRaw from "rehype-raw";
import "./md.css";
import HeadingRenderer from './headingRenderer';

function DocContainer(props) {

    const [doc, setDoc] = useState(null);
   
    useEffect(() => {
        
        const docPath = require("../../docs/" + props.doc + ".md");
        
        fetch(docPath)
        .then(response => {
            return response.text()
        })
        .then(text => {
            setDoc(text)
        })
        },[props.doc])

        // <ReactMarkdown children={doc} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}/>
    return (
        <Container>
            <div className='markdown-body' style={{ paddingTop: "20px", paddingBottom: "50px" }} >
                <ReactMarkdown children={doc} escapeHtml={false} rehypePlugins={[rehypeRaw]} components = {{h2: HeadingRenderer}}/>
            </div>
        </Container>
    )
}

export default DocContainer;