
import React, { useEffect, useState } from 'react';
import {Modal, Button} from "react-bootstrap";

function ConfirmDialog(props) {

  const handleClose = () => props.onClose();

  const handleConfirm = () => {
    props.onConfirm();
    
  }

return (
    <Modal show={props.message != null}>
    <Modal.Header closeButton>
      <Modal.Title>{props.title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{props.message}</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Cancel
      </Button>
      <Button variant="primary" onClick={handleConfirm}>
        OK
      </Button>
    </Modal.Footer>
  </Modal>
)
  
}

export default ConfirmDialog;
