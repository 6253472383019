import React from 'react';

//https://github.com/remarkjs/react-markdown/issues/404
//https://github.com/remarkjs/react-markdown/issues/69

const flatten = (text, child) => {
  return typeof child === 'string'
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flatten, text);
};

/**
 * HeadingRenderer is a custom renderer
 * It parses the heading and attaches an id to it to be used as an anchor
 */
const HeadingRenderer = props => {
  const children = React.Children.toArray(props.children);
  const text = children.reduce(flatten, '');
  const slug = text.toLowerCase().replace(/\W/g, '-');
 
  return React.createElement('h' + props.level, { id: slug }, props.children);
};

export default HeadingRenderer;