import axios from 'axios';
import jwt_decode from "jwt-decode";
import { getAppConfig } from "../config";
import { getOrgs } from './authController';

//Get settings for an organization
export const getSettings = async (accessToken, orgId) => {

  
    const appconfig = getAppConfig();
            
    const header = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };
    
    var url = appconfig.api + '/settings';
    if (orgId){
      url += "/" + orgId;
    }
    try{
      var result = await axios.get(url, header);
      return result;
    }
    catch(ex){
      return null;
    }
  
}

//compare current org settings to default settings and return diff
export const updateAllSettings = async (accessToken, differences) => {
  
  const appconfig = getAppConfig();

  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  try{
    var promises = []
    //separate the differences to update by org

    let orgs = differences.map(function(d){
      return d.org_id;
    });

    let uniqueOrgs = [...new Set(orgs)];

    uniqueOrgs.forEach(function(orgId){
      //Find diff for orgId
      let diffs = differences.filter(function(d){
        return d.org_id == orgId && d.newValue != null;
      });

      if (diffs.length > 0){
        promises.push(getSettings(accessToken, orgId).then(function(orgSettings){
          //update org settings with newValue in diff
          let updatedSettings = updateSettingsByDiff(orgSettings.data, diffs);
          //send update to server
          return updateSettings(accessToken, orgId, updatedSettings, true);
        }));
      }

    });
    return Promise.all(promises).then(function(results){
      var success = true;
      let failure = results.filter(function(result){
        return result.status != 200;
      })
      if (failure.length>0){
        return failure[0];
      }
      else{
        return results[0];
      }
    });
  }
  catch(ex){
    return ex;
  }
}

function updateSettingsByDiff(orgSettings, diffs){
  diffs.forEach(function(d){
    orgSettings.settingTypes.forEach(function(settingType){
      if (settingType.type == d.type){
        if (d.value == "missing"){
          //add
          var newSetting = {};
              newSetting.name = d.name;
              newSetting.value = d.default;
              newSetting.editByUser = d.editByUser;
              settingType.settings.push(newSetting)
        }
        else{
          settingType.settings.forEach(function(setting){
            if (d.name == setting.name){
              setting.value = d.newValue
            }
          });
        }
      }
    });
  })
  return orgSettings
}

//compare current org settings to default settings and return diff
export const compareAllSettings = async (accessToken, defaultSettings) => {
  
  const appconfig = getAppConfig();

  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  try{
    var promises = []
    var orgs = await getOrgs(accessToken)
    orgs.forEach(function(org){
      promises.push(getSettings(accessToken, org.id).then(function(orgSettings){
        let lstOrgDiff = getDiff(orgSettings.data, defaultSettings, org)
        return lstOrgDiff
      }));
    });
    return Promise.all(promises).then(function(results){
      var lstDiff = [];
      results.forEach(function(result){
        if (result.length > 0){
          result.forEach(function(setting){
            lstDiff.push(setting);
          })
        }
      })
      return lstDiff;
    })
  }
  catch(ex){
    return ex;
  }
}

//compare current org settings to default settings and return diff
export const compareSettings = async (accessToken, settings) => {
  
  const appconfig = getAppConfig();

  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  try{
    var defaultSettings = await getSettings(accessToken, "default")
      
    if (JSON.stringify(settings) == JSON.stringify(defaultSettings.data)){
      return [];
    }
    else{
      return getDiff(settings, defaultSettings.data)
    }
  }
  catch(ex){
    return ex;
  }
}

function getDiff(settings, defaultSettings, org){
  //return the difference
  var lstDifference = [];
  var lstSettings = [];
  var lstDefaultSettings=[];
  settings.settingTypes.forEach(function(settingType){
    settingType.settings.forEach(function(setting){
      var clonedSetting = {...setting};
      clonedSetting.type = settingType.type;
      lstSettings.push(clonedSetting);
    });
  });
  defaultSettings.settingTypes.forEach(function(settingType){
    settingType.settings.forEach(function(setting){
      var clonedSetting = {...setting};
      clonedSetting.type = settingType.type;
      lstDefaultSettings.push(clonedSetting);
    });
  });

  lstDefaultSettings.forEach(function(defaultSetting){
    var setting = lstSettings.filter(function(ds){
      return ds.name == defaultSetting.name;
    });
    if (setting.length>0){
      if (defaultSetting.value != setting[0].value){
        var diff = {}
        diff.type = setting[0].type;
        diff.name = setting[0].name;
        diff.value = setting[0].value;
        diff.editByUser = setting[0].editByUser;
        diff.default = defaultSetting.value;
        if (org){
          diff.org_name = org.display_name;
          diff.org_id = org.id;
        }
        lstDifference.push(diff);
      }
    }
    else{
      //setting missing
      var diff = {}
      diff.type = defaultSetting.type;
      diff.name = defaultSetting.name;
      diff.value = "missing"
      diff.editByUser = defaultSetting.editByUser;
      diff.default = defaultSetting.value;
      if (org){
        diff.org_name = org.display_name;
        diff.org_id = org.id;
      }
      lstDifference.push(diff);
    }
  });
  return lstDifference;
}

export const updateSettings = async (accessToken, orgId, settings, overwrite) => {
  
  const appconfig = getAppConfig();

  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var url = appconfig.api + '/settings';
  if (orgId){
    url += "/" + orgId;
  }
  
  try{
    //Before update, first fetch from the server to see if changes has been made
    if (!overwrite){
      var serverSettings = await getSettings(accessToken, orgId)
      if (serverSettings.data.uuid != settings.uuid){
        //Let's determine which job type has changed and see if it is something we can auto merge, i.e. two users updating different job type
        return "serverchanged"
      }
      else{
        //No server updates, check to see if client has real updates
        if (JSON.stringify(settings) == JSON.stringify(serverSettings.data)){
          return "noupdates"
        }
      }
    }
    var response = await axios.put(url, settings, header);
    return response;
  }
  catch(ex){
    return ex;
  }
}

export const createSettings = async (accessToken, orgId, settings) => {
  const appconfig = getAppConfig();
  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var url = appconfig.api + '/settings';
  if (orgId){
    url += "/" + orgId;
  }
  
  try{
  
    var response = await axios.post(url, settings, header);
    
    return response;
    
  }
  catch(ex){
    return ex;
  }
}
