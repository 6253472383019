import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import config from './aws-exports';
import { getAppConfig } from "./config";
import { Auth0Provider } from "@auth0/auth0-react";
import App from './App';

function getQueryParams(url) {
  const paramArr = url.slice(url.indexOf('?') + 1).split('&');
  const params = {};
  paramArr.map(param => {
      const [key, val] = param.split('=');
      params[key] = decodeURIComponent(val);
  })
  return params;
}

const values = getQueryParams(window.location.search);
//Get orgid from query string
//Electric company: "org_Hv3h33kG6kNjXb6z";
//Test water: org_Y5iToZDsVNVbP2pv
var org = values["organization"];
var superAdminOrg = null;
if (org && org.indexOf("|")>0){
  var orgs = org.split("|");
  superAdminOrg = orgs[1];
  org = orgs[0];
}
var invite = values["invitation"];

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider

const authconfig = getAppConfig();
var providerConfig = {
  domain: authconfig.domain,
  clientId: authconfig.clientId,
  ...(authconfig.audience ? { audience: authconfig.audience } : null),
  redirectUri: window.location.origin,
};

if (org){
  //providerConfig.organization = org;
  if (invite){
    providerConfig.organization = org;
    providerConfig.invitation = invite;
    providerConfig.state = "invite";
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<Auth0Provider {...providerConfig}>
  <App provider={providerConfig} params={values} org={org} superAdminOrg={superAdminOrg}/>
</Auth0Provider>
);
