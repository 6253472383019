import React, { useEffect, useState } from 'react';
import {Container, ButtonGroup, Row, Col, Button, Modal, Form } from "react-bootstrap";

function DefaultValueEditor(props) {

  const [resultingValue, setResultingValue] = useState(null); 
  const [type, setType] = useState(null); 
  const [func, setFunc] = useState(null); 
  const [cust, setCust] = useState(null);

  const handleClose = () => {
    props.onClose();
  }

  const handleConfirm = () => {
    props.onConfirm(resultingValue);
  }

  useEffect(() => {
   if (props.defaultValue.indexOf("function custom(")>=0){
    setType('custom');
    setCust(props.defaultValue);
    setFunc("");
   } else {
    setType('basic');
    setFunc(props.defaultValue)
    setCust("");
   }
   setResultingValue(props.defaultValue);
    
   },[props.field, props.defaultValue]);

  function toggleSelect(type){
    setType(type);

    if (type == "custom" && (cust == null || cust == "" || cust.indexOf("function custom(")<0)){
        setResultingValue("function custom(value, attributes){\n \n \n return value; \n}")
    } else if (type == "custom"){
        setResultingValue(cust);
    } else if (type == "basic"){
        if (func){
            setResultingValue(func);
        } else {
            setResultingValue("");
        }
        
    }
  }

  function fieldUpdate(e){
    setResultingValue(e.target.value);
    if (type == "custom"){
        setCust(e.target.value)
    } else {
        setFunc(e.target.value)
    }
  }
  
  function getDefaultValueEditorForm(){
    //style={{height:`${props.height - 130}px`}}

    return(
      <Form className='form'>
        <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>Type</Form.Label>
            <Col sm={8}>
            <ButtonGroup aria-label="Basic example">
              <Button variant="light" onClick={toggleSelect.bind(this,"basic")} active={type=="basic"}>
                Basic
              </Button>
              <Button variant="light" onClick={toggleSelect.bind(this,"custom")} active={type=="custom"}>
               Custom
              </Button>
            </ButtonGroup>
            </Col>
        </Form.Group>
        {type=="basic" &&
        <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>Function</Form.Label>
            <Col sm={8}>
            <Form.Select name="function" value={func} onChange={fieldUpdate.bind(this)}>
                <option value=""></option>
                <option value="{jobname}">{"{jobname}"}</option>
                <option value="{jobid}">{"{jobid}"}</option>
                <option value="{userid}">{"{userid}"}</option>
                <option value="{datetime}">{"{datetime}"}</option>
                <option value="{lat}">{"{lat}"}</option>
                <option value="{lon}">{"{lon}"}</option>
                <option value="Count(<model class name>)">{"Count(<model class name>)"}</option>
                <option value="Exist(<model class name1>,<model class name2>)">{"Exist(<model class name1>,<model class name2>)"}</option>
                <option value="Height(<model class name>)">{"Height(<model class name>)"}</option>
                <option value="BottomHeight(<model class name>)">{"BottomHeight(<model class name>)"}</option>
                <option value="TopHeight(<model class name>)">{"TopHeight(<model class name>)"}</option>
                <option value="Map(<model class name1>=<display name1>,<model class name2>=<display name2>)">{"Map(<model class name1>=<display name1>,<model class name2>=<display name2>)"}</option>
            </Form.Select>
            </Col>
         </Form.Group>
        }

        <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>Expression</Form.Label>
            <Col sm={8}><Form.Control as="textarea" rows={5} name="result" value={resultingValue} onChange={fieldUpdate.bind(this)}/></Col>
        </Form.Group>
        
      </Form>
    )
   }

  
return (
    <Modal dialogClassName="modal-dialog" show={props.show}>
      <Modal.Header>
        <Modal.Title>Default Value</Modal.Title>
      </Modal.Header>
      <Modal.Body>{getDefaultValueEditorForm()}</Modal.Body>
      <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Cancel
      </Button>
      <Button variant="primary" onClick={handleConfirm}>
        OK
      </Button>
      </Modal.Footer>
    </Modal>
  )
  
}

export default DefaultValueEditor;
