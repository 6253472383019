import React, { useEffect, useState } from 'react';
import {Container, ListGroup, Row, Col, Button, Modal, Form } from "react-bootstrap";
import { constructResultUrl, constructVisionLink } from '../../controllers/configController';
import { checkSignInStatus, getCredential, getPortalItems, getFeatureLayers, getFeatureLayerFields } from '../../controllers/esriAuthController';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';


function ExternalLinkEditor(props) {

  const [attributes, setAttributes] = useState(props.featureClass.attributes); 
  const [resultingUrl, setResultingUrl] = useState(null); 
  const [additionalParam, setAdditionalParam] = useState(null); 
  const [visionLink, setVisionLink] = useState(null); 
  const [type, setType] = useState(null); 
  const [direction, setDirection] = useState(null); 
  const [itemId, setItemId] = useState(null); 
  const [closeJob, setCloseJob] = useState(false); 
  const [returnPhoto, setReturnPhoto] = useState(false); 
  const [featureSourceUrl, setFeatureSourceUrl] = useState(null); 
  const [showItemLookup, setShowItemLookup] = useState(false); 
  const [showFeatureSourceLookup, setShowFeatureSourceLookup] = useState(false); 
  const [portalItems, setPortalItems] = useState([]);
  const [layers, setLayers] = useState([]);
  const [baseUrl, setBaseUrl] = useState(null); 
  
  const handleClose = () => {
    props.onClose();
  }

  const handleConfirm = () => {
    props.onConfirm(resultingUrl);
  }

  function getQueryParams(url) {
    const paramArr = url.slice(url.indexOf('?') + 1).split('&');
    const params = {};
    paramArr.map(param => {
        const [key, val] = param.split('=');
        params[key] = decodeURIComponent(val);
    })
    return params;
  }

  function getParams(params){
    var url = "";
    for (const key in params){
      if (url.length == 0){
        url = key + "=" + params[key]
      } else {
        url += "&" + key + "=" + params[key]
      }
    }
    return url;
  }

  function getAttrParams(attrs){
    var url = "";
    for (const key in attrs){
      if (attrs[key].indexOf("{")<0){
        if (url.length == 0){
          url = "\"" + key + "\":\"" + attrs[key] + "\"";
        } else {
          url += ",\"" + key + "\":\"" + attrs[key] + "\"";
        }
      }
    }
    return url;
  }

  useEffect(() => {
    
    //parse url
    //https://fieldmaps.arcgis.app?referenceContext=updateFeature&
    //itemID=8c69ea1a3bc84c47a630229bcd82bf57&closeJob=true&
    //featureSourceURL=https://demo-agx.sspinnovations.com/server/rest/services/Hosted/SSP_Vision_Capture_FieldMaps/FeatureServer/13&
    //featureID=redirectParam&
    //featureAttributes={"height":"{Height}","material":"{Material}","number":"{Number}","grid_id":"{Grid ID}"}

    //https://survey123.arcgis.app/?
    //itemID=d1e3c41c7b18410eaab65eaf4d2b912d
    //?closeJob=true
    //?callback=https://sspapp.link/visiondev
    //?field:height={Height}
    //?field:material={Material}
    //?field:number={Number}
    //?field:grid_id={Grid ID}

    if (props.url.length > 0){
      let domain = props.url.substr(0,props.url.indexOf("?"));
      let appType = domain.substr(8,props.url.indexOf(".")-8);
      let params = getQueryParams(props.url);
      if (appType.toLowerCase() != "fieldmaps" && appType.toLowerCase() != "survey123" && props.url.indexOf("?")<0){
        
          appType = "featureservice"
          setType(appType);
          setFeatureSourceUrl(props.url);
          /*
          if (params["featureAttributes"]){
            var selectedAttributes = JSON.parse(params["featureAttributes"]);
            for (var selAttr in selectedAttributes) {
              var matchingAttrs = allAttributes.filter(function(a){
                return a.name.toLowerCase().replace(" ","_") == selAttr.toLowerCase();
              })
              if (matchingAttrs.length > 0){
                matchingAttrs[0].checked = true
              }
            }
            
            setAttributes(allAttributes);
          }
          */
        
      } else {
        if (appType.toLowerCase() != "fieldmaps" && appType.toLowerCase() != "survey123"){
          appType = "other"
        }
        
        setBaseUrl(domain)
        setType(appType);
        
        delete params["featureID"];
  
        setItemId(params["itemID"]);
        delete params["itemID"];
  
        setFeatureSourceUrl(params["featureSourceURL"]);
        delete params["featureSourceURL"];
  
        if (params["closeJob"] == "true"){
          setCloseJob(true);
        }
        delete params["closeJob"];

        if (params["returnPhoto"] == "true"){
          setReturnPhoto(true);
        }
        delete params["returnPhoto"];
  
        if (params["referenceContext"] == "updateFeature" && params["summary"] == "difference" && appType == "fieldmaps"){
          setDirection("startFromExternalWithDifferenceSummary");
        } else if (params["referenceContext"] == "updateFeature" && appType == "fieldmaps"){
          setDirection("startFromExternal");
        } else if (params["referenceContext"] == "addRelatedFeature" && appType == "fieldmaps"){
            setDirection("startFromExternalWithRelatedFeature");
        } else if (params["referenceContext"] == "updateFeature" && appType == "other"){
          setDirection("startFromExternal");
        }
        else if (appType == "fieldmaps" || appType == "other"){
          setDirection("startFromVision");
        }
        else if (params["callback"] == "https://sspapp.link/visiondev" && appType == "survey123"){
          setDirection("startFromVision");
        }
        else if (appType == "survey123"){
          setDirection("startFromExternal");
        }
        delete params["referenceContext"];
        delete params["callback"];
        
        var allAttributes = [...props.featureClass.attributes];
      }
 
      if ((appType.toLocaleLowerCase() == "fieldmaps" || appType.toLocaleLowerCase() == "other") && params["featureAttributes"]){
          var selectedAttributes = JSON.parse(params["featureAttributes"]);
          for (var selAttr in selectedAttributes) {
            var matchingAttrs = allAttributes.filter(function(a){
              return a.name.toLowerCase().replace(" ","_") == selAttr.toLowerCase();
            })
            if (matchingAttrs.length > 0){
              matchingAttrs[0].checked = true
            }
          }
          
          setAdditionalParam(getAttrParams(selectedAttributes));
          setAttributes(allAttributes);
      }
      else{
        //?field:height={Height}
        for (const key in params){
          if (key.indexOf("field:")>=0){
            var matchingAttrs = allAttributes.filter(function(a){
              let name = key.replace("field:","");
              return a.name.toLowerCase().replace(" ","_") == name;
            })
            if (matchingAttrs.length > 0){
              matchingAttrs[0].checked = true
            }
            delete params[key];
          }
        };

        setAttributes(allAttributes);
        setAdditionalParam(getParams(params));

      }
      
    }
    else{
      setType("fieldmaps");
      setItemId(null);
      setFeatureSourceUrl(null);
      setCloseJob(false);
      setReturnPhoto(false);
      setDirection("startFromExternal");
      var allAttributes = [...props.featureClass.attributes];
      allAttributes.forEach(function(a){
        delete a.checked;
      });
      setAttributes(allAttributes);
    }

    
    
   },[props.featureClass, props.url]);

  
  useEffect(() => {
    updateResultUrl();
  },[type, direction, itemId, closeJob, returnPhoto, featureSourceUrl, attributes, additionalParam]);

   function handleAttributeCheckChanged(at, e){
     at.checked = e.currentTarget.checked;
     updateResultUrl();
   }

   function fieldUpdate(e){

      switch(e.currentTarget.name){
        case "type":
          setType(e.currentTarget.value);
          break;
        
        case "baseUrl":
          setBaseUrl(e.currentTarget.value);
          break;

        case "direction":
          setDirection(e.currentTarget.value);
          break;

        case "itemId":
          setItemId(e.currentTarget.value);
          break;

        case "closeJob":
          setCloseJob(e.currentTarget.checked);
          break;

        case "returnPhoto":
          setReturnPhoto(e.currentTarget.checked);
          break;

        case "featureSourceUrl":
          setFeatureSourceUrl(e.currentTarget.value);
          break;

        case "item":
          setItemId(e.currentTarget.value);
          break;

        case "featureSource":
          fetchLayers(e.currentTarget.value);
          setFeatureSourceUrl(e.currentTarget.value + "/0");
          break;

        case "featureLayer":
          setFeatureSourceUrl(getFeatureSource(featureSourceUrl) + "/" + e.currentTarget.value);
          break;

        case "additionalParam":
          if (type && type.toLocaleLowerCase() == "fieldmaps"){
            var fields = e.currentTarget.value;
            if (fields.indexOf(":")>0){
              if (fields.indexOf(",")>0){
                var param = "";
                var fieldsArray = fields.split(",");
                fieldsArray.forEach(function(field){
                  if (field.indexOf(":")>0){
                    var fieldArray = field.split(":");
                    if (fieldArray[0].indexOf("\"")<0){
                      if (param.length == 0){
                        param = "\"" + fieldArray[0] + "\":\"" + fieldArray[1] + "\"";
                      } else {
                        param = param + ",\"" + fieldArray[0] + "\":\"" + fieldArray[1] + "\"";
                      }
                      
                    } else {
                      if (param.length == 0){
                        param = fieldArray[0] + ":" + fieldArray[1];
                      } else {
                        param = param + "," + fieldArray[0] + ":" + fieldArray[1];
                      }
                    }
                  } else {
                    param = param + "," + field;
                  }
                
                })
                setAdditionalParam(param);
              } else {
                var fieldArray = fields.split(":");
                if (fieldArray[0].indexOf("\"")<0){
                  if (fieldArray[1].indexOf("\"")<0){
                    setAdditionalParam("\"" + fieldArray[0] + "\":\"" + fieldArray[1] + "\"");
                  } else {
                    setAdditionalParam("\"" + fieldArray[0] + "\":" + fieldArray[1]);
                  }
                  
                } else {
                  setAdditionalParam(e.currentTarget.value);
                }
              }
            }else{
              setAdditionalParam(e.currentTarget.value);
            }
          } else {
            setAdditionalParam(e.currentTarget.value);
          }
          
        
        default:

      }
   }

   function getAttributeDisplayName(at){
    if (at.displayName){
      return at.displayName;
    }
    else{
      return at.name;
    }
   }

   function updateResultUrl(){
    var url = "";
    var visionLink = "";
    if (type){
      url = constructResultUrl(type, direction, itemId, closeJob, returnPhoto, featureSourceUrl, attributes, additionalParam, baseUrl);
      visionLink = constructVisionLink(type, direction, props.jobType.jobName,props.featureClass.featureClass, attributes, returnPhoto);
    }
    setResultingUrl(url);
    setVisionLink(visionLink);
   }

function fetchPortalItems(){
  
  getPortalItems().then(function(query){
      setPortalItems(query.results);
  });
  
}

function fetchLayers(url){
  
  getFeatureLayers(url).then((layers) => {
      setLayers(layers);
  });
  
}

function handleFeatureSourceLookup(e){
  if (showFeatureSourceLookup){
    setShowFeatureSourceLookup(false);
  }
  else {
    checkSignInStatus(props.portals[0].portalUrl, props.portals[0].clientId)
      .then(() => {
        fetchPortalItems();
        setShowFeatureSourceLookup(true);
      })
      .catch(() => {
        // If the user is not signed in, generate a new credential.
        getCredential()
          .then((result) => {
            fetchPortalItems();
            setShowFeatureSourceLookup(true);
          }).catch((err) => {
            setShowFeatureSourceLookup(false);
          });
      });
    setShowFeatureSourceLookup(true);
  }
  
}

function handleItemLookup(e){
  if (showItemLookup){
    setShowItemLookup(false);
  } else {
    checkSignInStatus(props.portals[0].portalUrl, props.portals[0].clientId)
      .then(() => {
        fetchPortalItems();
        setShowItemLookup(true);
      })
      .catch(() => {
        // If the user is not signed in, generate a new credential.
        getCredential()
          .then((result) => {
            fetchPortalItems();
            setShowItemLookup(true);
          }).catch((err) => {
            setShowItemLookup(false);
          });
      });
  }
}

function getItemPicklist(){
    
  let items = portalItems.filter((item,i) => {
    if (type == "fieldmaps"){
      return item.type == "Web Map";
    } else {
      return item.type == "Form";
    }
  });
  return (items.map((item,i) => (
    <option value={item.id}>{item.title}</option>
    )));
}

function getFeatureSourcePicklist(){
    
  let items = portalItems.filter((item,i) => {
      return item.type == "Feature Service";
  });
  return (items.map((item,i) => (
    <option value={item.url}>{item.title}</option>
    )));
}

function getFeatureLayerPicklist(){
  
  return (layers.map((item,i) => (
    <option value={item.id}>{item.name}</option>
    )));
}

function getFeatureSource(fsUrl){
  if (fsUrl && fsUrl.length>0){
    var endIndex = fsUrl.lastIndexOf("/")
    var fs = fsUrl.substring(0, endIndex);
    if (fs){
      fetchLayers(fs);
    }
    return fs;
  }
  else{
    return "";
  }
}

function getFeatureLayer(fsUrl){
  if (fsUrl && fsUrl.length>0){
    var startIndex = fsUrl.lastIndexOf("/")+1;
    var endIndex = fsUrl.length;
    var fl = fsUrl.substring(startIndex,endIndex);
    return fl;
  }
  else{
    return "";
  }

}

function getAdditionalParamPlaceholder(){
  if(type && type.toLocaleLowerCase() == "fieldmaps") {
    return "<field>:<value>,<field2>:<value2>"
  } else {
    return "field:<field>=<value>&field:<field2>=<value2>"
  }
}
  function getExternalLinkForm(){
    //style={{height:`${props.height - 130}px`}}

    return(
      <Form className='form'>
        <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>Type</Form.Label>
            <Col sm={8}>
              <Form.Select name="type" value={type} onChange={fieldUpdate.bind(this)}>
                <option value="fieldmaps">Field Maps</option>
                <option value="survey123">Survey 123</option>
                <option value="featureservice">Feature Service</option>
                <option value="other">Other</option>
              </Form.Select>
            </Col>
        </Form.Group>
        {((type && type != "featureservice")) &&
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm={4}>Direction</Form.Label>
          <Col sm={8}>
              <Form.Select name="direction" value={direction} onChange={fieldUpdate.bind(this)}>
              <option value="startFromExternal">Start from External</option>
              <option value="startFromExternalWithDifferenceSummary">Start from External with Difference Summary</option>
              <option value="startFromExternalWithRelatedFeature">Start from External and Add Related Feature</option>
              <option value="startFromVision">Start from Vision</option>
              </Form.Select>
          </Col>
        </Form.Group>
        }
        {(type && type == "other") &&
           <Form.Group as={Row} className="mb-2">
           <Form.Label column sm={4}>Base Url</Form.Label>
           <Col sm={8}><Form.Control type="input" name="baseUrl" placeholder={"External App universal link"} value={baseUrl} onChange={fieldUpdate.bind(this)}/></Col>
           </Form.Group>
        }
        {(showItemLookup == false && ((type && type != "featureservice")) ) &&
          <Form.Group as={Row} className="mb-2">
              {(type == "fieldmaps") &&
              <Form.Label column sm={4}>FieldMaps Web Map</Form.Label>
            }
            {(type == "survey123") &&
              <Form.Label column sm={4}>Survey123 Form</Form.Label>
            }
            {(type == "survey123" || type == "fieldmaps") &&
              <Col sm={7}><Form.Control type="input" name="itemId" value={itemId} onChange={fieldUpdate.bind(this)}/></Col>
            }
            {(type == "survey123" || type == "fieldmaps") &&
              <Col sm={1}><Button variant="primary" type="button" onClick={handleItemLookup.bind(this)}>...</Button></Col>
            }
           
          </Form.Group>
        }
        {showItemLookup &&
          <Form.Group as={Row} className="mb-2">
            {(type == "fieldmaps") &&
              <Form.Label column sm={4}>FieldMaps Web Map</Form.Label>
            }
            {(type == "survey123") &&
              <Form.Label column sm={4}>Survey123 Form</Form.Label>
            }
            <Col sm={8}>
              <Form.Select name="item" value={itemId} onChange={fieldUpdate.bind(this)}>
              <option value=""></option>
                {getItemPicklist()}
              </Form.Select>
            </Col>
          </Form.Group>
        }
         {((type && type != "featureservice")) &&
        <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>Close Job</Form.Label>
            <Col sm={8}><Form.Check type="switch" name="closeJob" label="Close Job" checked={closeJob} onChange={fieldUpdate.bind(this)} /></Col>
            {(type && type == "other") &&
              <Form.Label column sm={4}>Return Photo</Form.Label>
            }
            {(type && type == "other") &&
              <Col sm={8}><Form.Check type="switch" name="returnPhoto" label="Return Photo" checked={returnPhoto} onChange={fieldUpdate.bind(this)} /></Col>
            }
        </Form.Group>
        }
      {(type && (type.toLocaleLowerCase() == "fieldmaps" || type.toLocaleLowerCase() == "featureservice") && showFeatureSourceLookup == false) &&
        <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>Feature Source Url</Form.Label>
            <Col sm={7}><Form.Control type="input" name="featureSourceUrl" value={featureSourceUrl} onChange={fieldUpdate.bind(this)}/></Col>
            <Col sm={1}><Button variant="primary" type="button" onClick={handleFeatureSourceLookup.bind(this)}>...</Button></Col>
        </Form.Group>
      }
      {(type && (type.toLocaleLowerCase() == "fieldmaps" || type.toLocaleLowerCase() == "featureservice") && showFeatureSourceLookup == true) &&
        <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>Feature Source</Form.Label>
            <Col sm={4}>
              <Form.Select name="featureSource" value={getFeatureSource(featureSourceUrl)} onChange={fieldUpdate.bind(this)}>
              <option value=""></option>
                  {getFeatureSourcePicklist()}
              </Form.Select>
            </Col>
            <Col sm={4}>
            <Form.Select name="featureLayer" value={getFeatureLayer(featureSourceUrl)} onChange={fieldUpdate.bind(this)}>
            <option value=""></option>
                  {getFeatureLayerPicklist()}
              </Form.Select>
            </Col>
        </Form.Group>
      }
      {((type && type != "featureservice") && attributes) &&
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm={4}>Attributes</Form.Label>
            <Col sm={8}>
              <ListGroup style={{height:`165px`, overflow:'auto'}}>
                    {attributes.map((at,i) => (
                    <ListGroup.Item key={at.name} className="d-flex justify-content-between align-items-start">
                        <Row>
                        <Col className="d-flex justify-content-between align-items-start">
                          <Form.Check type="checkbox" id={at.name} label={getAttributeDisplayName(at)} checked={at.checked}  onChange={handleAttributeCheckChanged.bind(this, at)}/>
                        </Col>
                        </Row>
                    </ListGroup.Item>
                    ))}
              </ListGroup>
              </Col>
        </Form.Group>
       }
       {((type && type != "featureservice")) &&
        <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>Additional Parameters</Form.Label>
            <Col sm={8}><Form.Control type="input" name="additionalParam" placeholder={getAdditionalParamPlaceholder()} value={additionalParam} onChange={fieldUpdate.bind(this)}/></Col>
        </Form.Group>
       }
        <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>Resulting Url</Form.Label>
            <Col sm={8}><Form.Control as="textarea" rows={3} name="resulting Url" value={resultingUrl} onChange={fieldUpdate.bind(this)}/></Col>
        </Form.Group>
        {((type && type != "featureservice")) &&
        <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>Vision Link</Form.Label>
            <Col sm={8}><Form.Control type="input" name="Vision Link" value={visionLink} /></Col>
        </Form.Group>
        }
      </Form>
    )
   }

  
return (
    <Modal dialogClassName="modal-dialog" show={props.show}>
      <Modal.Header>
        <Modal.Title>External Link</Modal.Title>
      </Modal.Header>
      <Modal.Body>{getExternalLinkForm()}</Modal.Body>
      <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Cancel
      </Button>
      <Button variant="primary" onClick={handleConfirm}>
        OK
      </Button>
      </Modal.Footer>
    </Modal>
  )
  
}

export default ExternalLinkEditor;
