import esriId from "@arcgis/core/identity/IdentityManager";
import OAuthInfo from "@arcgis/core/identity/OAuthInfo";
import Portal from "@arcgis/core/portal/Portal";
import PortalItem from "@arcgis/core/portal/PortalItem";
import PortalQueryParams from "@arcgis/core/portal/PortalQueryParams";
import WebMap from "@arcgis/core/WebMap";
import FeatureService from "@arcgis/core/rest/featureService/FeatureService";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

var clientId, portalUrl;

export function initEsriAuth(in_portalUrl, in_clientId){
   
    if (portalUrl != in_portalUrl || clientId != in_clientId){
        portalUrl = in_portalUrl;
        clientId = in_clientId;

        //Create a new OAuthInfo object.
        const info = new OAuthInfo({
            // Swap this ID out with an app ID registered in your ArcGIS Organization.
            appId: clientId,
            //appId: "aEjfGyV2LzgUVagi",
            //appId:"3AbgO0Bn7DVIMpYA",//sample
            // Add the portalUrl property if using your own portal.
            portalUrl: portalUrl,
            //portalUrl: "https://demo-agx.sspinnovations.com/portal",
            //portalUrl:"https://demo-unserver.sspinnovations.com/unserver",
            // Set the authNamespace property to prevent the user's signed in state
            // from being shared with other apps on the same domain with the same authNamespace value.
            // authNamespace: "portal_oauth_inline",
            // Set popup to true to show the OAuth sign-in page in a separate popup window.
            popup: true,
            //popupCallbackUrl: "oauth-callback.html"
        });

        // Call the checkSignIn function to see if the user is already signed in.
        // Add the OAuthInfo to the IdentityManager.
        esriId.registerOAuthInfos([info]);
    }
    
}

export function getCredential(){
    return esriId
      .getCredential(portalUrl + "/sharing", {
        // Set the following property to false to not show a dialog
        // before the OAuth popup window is open.
        oAuthPopupConfirmation: false,
      });
}

export function checkSignInStatus(in_portalUrl, in_clientId){
    initEsriAuth(in_portalUrl, in_clientId);

    return esriId
    .checkSignInStatus(portalUrl + "/sharing");
}

export function getPortalItems(){
    const portal = new Portal({
        authMode: "immediate"
      });
      // Check if using a portal other than ArcGIS Online.
      if (portalUrl !== "https://www.arcgis.com") {
        portal.url = portalUrl;
      }
      // Load the portal, display the name and username, then call the query items function.
      return portal.load().then(() => {
        //navigationUser.fullName = portal.user.fullName;
        //navigationUser.username = portal.user.username;
        //navLogo.description =
        //  "Gallery of queried portal items displayed below. To sign out, click on the logged in user button.";
        return queryItems(portal);
      });
  }

  // Function to query for portal items.
  function queryItems(portal) {
    // Create query parameters for the portal search.
    const queryParams = new PortalQueryParams({
      query: "owner:" + portal.user.username,
      sortField: "num-views",
      sortOrder: "desc",
      num: 20
    });
    // Query the items based on the queryParams created from the portal.
    return portal.queryItems(queryParams);
  }

  export function getFeatureLayers(url){
    //Fetch layers:
    const featureService = new FeatureService({
      url: url
     });

     return featureService.load().then(() => {
      let layers = featureService.layerInfos;
      let tables = featureService.tableInfos;

      return layers.concat(tables);
    });
  }

  export function getFeatureLayerFields(featureServiceUrl, id){
    //populate fields:
    const layer = new FeatureLayer({
        // URL to the service
        url: featureServiceUrl + "/" + id.toString()
      });
  
      return layer.load().then(() => {
        let fields = layer.fields;
        return fields;
      });
  }
