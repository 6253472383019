
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Accordion, Form, Button } from "react-bootstrap";
import { JsonViewer } from '@textea/json-viewer'
import Canvas from './canvas';
import ImageOverlay from './imageOverlay';

function JobDetail(props) {
  const [job, setJob] = useState(props.job);
  const [timeoutId, setTimeoutId] = useState(0);
  const [defaultKey, setdefaultKey] = useState("data");

  useEffect(_ => {
    
    //filter out repeated org values
    if (props.job.attributes["config_org_id"] == props.job.attributes["org_id"]){
      //same value as org_id, don't need to display again
      removeField("config_org_id")
    }

    if (props.job.attributes["config_org_name"] == props.job.attributes["org_name"]){
      //same value as org_id, don't need to display again
      removeField("config_org_name")
    }
  }, [props.job.attributes]);

  function removeField(fieldname){
    var fieldIndex = props.fields.findIndex(function(f){
      return f.name == fieldname
    })
    if (fieldIndex > -1) { // only splice array when item is found
      props.fields.splice(fieldIndex, 1); // 2nd parameter means remove one item only
    }
  }

  function getPhotos(){
    return (
      <div style={{height:`390px`, overflow:'auto'}}>{props.photos.map((p) => (
        <div><div>{p.image_name}</div><div className="jobimage"><img src={p.presigned_url}/></div></div>
        ))}
        <JsonViewer value={props.photos} collapsed={true}/>
      </div>
    )
  }

  function getObservations(){
    return (
      <div style={{height:`390px`, overflow:'auto'}}>{props.observations.map((p) => (
        <div>
            {p.sidecar_presigned_url != null && p.sidecar_presigned_url.length > 0 &&
            <div>
              <ImageOverlay image={p} type="bbox" thumbnail={true} canEdit={false} />
            </div>
            }
            {p.sidecar_presigned_url == null &&
               <div className='jobimage'><img src={p.presigned_url}/></div>
            }
            <div>&nbsp;</div>
          </div>
        ))}
        <JsonViewer value={props.observations} collapsed={true}/>
      </div>
    )
  }

  function getJson(){
    return (<JsonViewer value={props.job} />)
  }
  
  function getSysLog(){
    return (
      <div style={{height:`390px`, overflow:'auto'}}>{props.sysLog}
      </div>
    )
  }

  function getDebugLog(){
    return (
      <div style={{height:`390px`, overflow:'auto'}}>{props.debugLog}
      </div>
    )
  }

  function getCanvas(){
    //width:`${window.innerWidth/2 - 20}px`
    return (
      <div style={{height:`450px`, overflow:'auto'}}>
        <Canvas geoFeatures={props.geoFeatures}></Canvas>
      </div>
      
    )
  }

  function fieldUpdate(e){
    if (timeoutId > 0){
      clearTimeout(timeoutId);
    }
      
    job.attributes[e.currentTarget.name] = e.currentTarget.value;
    
    var updatedJob = {...job}; //Clone it so that it will trigger state change
    setJob(updatedJob);

    var tid = setTimeout(function(){
      var comment = job.attributes["comments"];
      job.comments = comment;
      props.onCommentUpdate(comment);
    }, 1000);

    setTimeoutId(tid);
  }

  function getFieldRow(f){
    if (f.name == "comments"){
      return (
        <Row>
          <Col className="f1">{f.alias}</Col>
          <Col className="f1"><Form.Control style={{height:`25px`}} type="input" name={f.name} value={job.attributes[f.name]} onChange={fieldUpdate.bind(this)}/></Col>
        </Row>
      )
    }
    else if (f.name == "date"){
      var dateStr = props.job.attributes[f.name];
      //from yyyyMMdd-HHmmss to 2011-04-11T10:20:30Z
      var jobName = props.job.attributes["name"];
      //parse date from jobName
      var dateFromName = jobName.substr(jobName.indexOf("-")+ 1, jobName.length - jobName.indexOf("-"));
      var date;
      if (dateStr != dateFromName){
        date = new Date(dateStr.substr(0,4) + "-" + dateStr.substr(4,2) + "-" + dateStr.substr(6,2) + "T" + dateStr.substr(9,2) + ":" + dateStr.substr(11,2) + ":" + dateStr.substr(13,2) + "Z");
      }
      else{
        date = new Date(dateStr.substr(0,4) + "-" + dateStr.substr(4,2) + "-" + dateStr.substr(6,2) + "T" + dateStr.substr(9,2) + ":" + dateStr.substr(11,2) + ":" + dateStr.substr(13,2));
      }
     
      var localDate = date.toString();
      
      return (
        <Row>
          <Col className="f1">{f.alias}</Col>
          <Col className="f1">{localDate}</Col>
        </Row>
      )
    }
    else{
      return (
      <Row>
        <Col className="f1">{f.alias}</Col>
        <Col className="f1">{props.job.attributes[f.name]}</Col>
      </Row>
      )
    }
  }

  function getDefaultKey(){
      return "data"
  }
  return (
      <Accordion defaultActiveKey={getDefaultKey()} style={{height:`${props.height - 200}px`, overflow:'auto'}}>
            <Accordion.Item eventKey="data">
              <Accordion.Header>Data</Accordion.Header>
              <Accordion.Body>
              <Container fluid>
              {props.fields.map((f) => (
                  getFieldRow(f)
              ))}
              </Container>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="photo">
              <Accordion.Header>Photos</Accordion.Header>
              <Accordion.Body>
               {getPhotos()}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="observation">
              <Accordion.Header>Observations</Accordion.Header>
              <Accordion.Body>
               {getObservations()}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="json">
              <Accordion.Header>JSON</Accordion.Header>
              <Accordion.Body>
               {getJson()}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="sys">
              <Accordion.Header>System Log</Accordion.Header>
              <Accordion.Body>
               {getSysLog()}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="debug">
              <Accordion.Header>Debug Log</Accordion.Header>
              <Accordion.Body>
               {getDebugLog()}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="arview">
              <Accordion.Header>AR Coords</Accordion.Header>
              <Accordion.Body>
               {getCanvas()}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
      
     )
  
}

export default JobDetail;
